.mobile-nav-overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

    .mobile-nav-overlay--visible {
        display: block;
        // background-color: rgba(0,0,0,.9);
        background: linear-gradient(45deg, #0d4979, #5ec6f5);
        z-index: 10001;
    }

    .mobile-nav-overlay__close {
        @include retina-image('/imgs/icon_close', 25px, 'png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 25px;
        float: right;
        cursor: pointer;
        margin: 20px 30px;
        @include size(25px);
        &:hover {
            cursor: pointer;
        }

    }

    .mobile-nav-overlay__nav {
        // border: 1px solid red;
        text-align: center;
        display: inline-block;
        width: 100%;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        li {
            // border: 1px solid green;
            text-align: center;
            padding: 18px 0;
        }

        a {
            font-size: 2em;
            color: #fff;
            font-family: $font-family-main-book;
            font-size: $font-size-l;
            display: inline-block;
            // border: 1px solid yellow;
        }
    }
