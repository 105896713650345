.c-choose-apartment {
    max-width: 1240px;
    margin: 0 auto;
}

    .c-choose-apartment--wide {
        // padding-left: 100px;
        // padding-right: 100px;
        max-width: 110vh;
        margin: 0 auto;
    }

        .c-choose-apartment__help {
            background: url('/imgs/arrow-to-bottom-grey_2x.png') center bottom / 18px 39px no-repeat;
            padding-bottom: 50px;
            margin: 0 auto 20px;
            text-align: center;
            span {
                color: #51565B;
                text-transform: uppercase;
                font-size: $font-size-l;
            }
        }



        .c-choose-apartment__floor {
            // border: 1px solid blue;
            position: relative;
            width: 100%;
            img {
                // margin-top: 40px;
                // border: 1px solid black;
                margin-bottom: 0;
            }
            svg {
                @include size(100%);
                // max-width: 1240px;
                position: absolute;
                left: 0px;
                top: 0px;
                padding: 0;
                margin-top: -5px;
                // border: 1px solid red;
            }
        }

            .c-choose-apartment__link {
                polygon {
                    opacity: 0;
                    fill: $brand-color-3;
                    stroke: $brand-color-6;
                    stroke-width: 10;
                    transition: all 0.25s ease-in-out;

                }
                text {
                    opacity: 0;
                    transition: all 0.25s ease-in-out;
                    text-anchor: middle;
                }
                &.inactive {
                    polygon {
                        opacity: .7;
                        fill: #000;
                    }
                }
                &[data-sold='prodat'] {
                    polygon {
                        opacity: .7;
                        fill: #ff6666;
                    }
                    text {
                        opacity: 1;
                    }
                }
                &[data-sold='rezervisan'] {
                    polygon {
                        opacity: .7;
                        fill: #ffdf8f;
                    }
                    text {
                        opacity: 1;
                    }
                }
                &:hover {
                    polygon {
                        opacity: .7;
                    }
                    text {
                        opacity: 1;
                    }
                }
            }



        .c-choose-apartment__checklist {
            margin-top: -22vw;
            ul {
                text-align: left;
                li {
                    display: flex;
                    align-items: center;
                    input {
                        @include size(32px 22px);
                        margin-right: 10px;
                        cursor: pointer;
                        &:checked {
                            background-image: url('/imgs/input_checked_2x.png');
                            background-position: center;
                            background-size: 16px 12px;
                            background-repeat: no-repeat;
                        }
                        &:checked, &:focus, &:hover {
                            outline: none;
                        }
                        &.garsonjere {
                            background-color: #8ddd78;
                        }
                        &.jednoiposobni {
                            background-color: #a1b865;
                        }
                        &.dvosobni {
                            background-color: #a1b865;
                        }
                        &.dvoiposobni {
                            background-color: #ffdf88;
                        }
                        &.trosobni {
                            background-color: #ffdf88;
                        }
                        &.cetvorosobni {
                            background-color: #febf33;
                        }
                    }
                    label {
                        font-family: $font-family-main-book;
                        line-height: 1;
                        cursor: pointer;
                    }
                    + li {
                        margin-top: 10px;
                    }
                }
            }
        }

        .c-choose-apartment__floor-num {
            width: 190px;
            margin: 50px auto 0;
            padding-left: 55px;
            background: url('/imgs/north_2x.png') left center / contain no-repeat;
            h2 {
                margin-bottom: 0;
            }
            span {
                font-family: $font-family-main-book;
                color: #BAC4D1;
            }
        }
