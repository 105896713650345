.c-objects-slider {
    background: linear-gradient(45deg, #0D4979 0%, #5EC6F5 100%);
    border-top: 5px solid #fff;
    padding: 1.5rem;
    h1 {
        color: #fff;
        text-align: center;
        font-family: $font-family-main-light;
        text-transform: uppercase;
    }
}

    .c-objects-slider__container {
        background: url('/imgs/bg-logo-objects_2x.png') center / contain no-repeat;
        // border: 1px solid red;
        padding: 3rem 3rem 1rem;
    }


    .c-objects-slider__carousel {

    }

    .c-objects-slider__carousel-item {
        text-align: center;
        margin: 20px;
        transform: scale(.8);
        transition: all .25s ease;
        img {
            outline: 5px solid #113459;
            outline-offset: 10px;
            margin-bottom: 40px;
            box-shadow: 0 10px 20px rgba(0,0,0,.4);
        }
        &.slick-current {
            transform: scale(1);
            img {
                outline-color: #99BEE5;
            }
            span {
                display: block;
            }
        }
        span {
            color: #fff;
            display: none;
            font-family: $font-family-main-light;
            font-size: $font-size-l;
            text-transform: uppercase;
        }
    }
