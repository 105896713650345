/* Use the following styles if you experience 'flicker' between the original image and the masked one. */
img[data-clipPath]{
  /* hide original imgs */
  visibility: hidden;
}

.svgMask image {
  /* IE fix */
  visibility: visible;
}

/* target <image> inside SVG to take advantage of the mask, instead of the SVG bounding box */

svg image {
  opacity: 0;
  transition: all .25s ease-in-out;
  cursor: pointer;
  &:hover, &:focus, &:active {
      opacity: 1;
  }
}
