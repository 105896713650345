.c-contact-us {

}

    .c-contact-us__container {
        background-color: #e1e4e9;
        @media (min-width: $screen-md-min) {
            display: flex;
            align-items: center;
        }
    }

    .c-contact-us__map{
        height: 300px;
        @media (min-width: $screen-md-min) {
            height: 740px;
        }
    }


    .c-contact-us__form {
        background-color: #e1e4e9;
        padding: 2.5rem 2rem;
        h1 {
            font-family: $font-family-main-book;
            margin-bottom: 2.5rem;
            color: #51565B;
        }
        p {
            margin-bottom: 1.875rem;
            font-family: $font-family-main-light;
            // font-size: $font-size-s;
            color: #51565B;
        }
        @media (min-width: $screen-sm-min) {
            padding: 2.5rem 5.625rem;
        }
    }

        .c-contact-form__input {
            width: 100%;
            margin-bottom: 25px;
            height: 50px;
            padding: 2px 10px 0 15px;
            background-color: #F7F7F7;
            border: 1px solid #9BA5B1;
            font-size: $font-size-s;
            transition: all 0.3s ease-in-out;
            color: #9BA5B1;
            &:focus {
                outline: none;
                border: 1px solid $brand-color-1;
            }
            &::-webkit-input-placeholder {
                color: #BBB;
            }
        }

            .c-contact-form__input--question {
                min-height: 185px;
                padding-top: 10px;
            }

            .c-contact-form__submit {
                display: block;
                margin: 0 auto;
                color: $white;
                background-color: #4da8d5;
                border: none;
                border-radius: 300px;
                padding: 15px 50px 13px;
                font-size: $font-size-base;
                letter-spacing: 2px;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background-color: #a5d985;
                }
            }



.input--error {
    border: 1px solid $brand-color-2;
}
