.c-apartment-details {
    @media (min-width: $screen-sm-min) {
        height: 1100px;
    }
    @media (min-width: $screen-md-min) {
        height: 1230px;
    }
}

    .c-apartment-details__skica {
        height: 100%;
        display: inline-block;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 50px 15px;
        @media (min-width: $screen-sm-min) {
            width: calc(100% - 410px);
        }
        img {
            display: block;
            @include size(100% auto);
            object-fit: contain;
            margin: 0 auto;
            // border: 5px solid #fff;
            // box-shadow: 0 0 10px rgba(0,0,0,.35);
            @media (min-width: $screen-md-min) {
                height: 100%;
                width: auto;
                max-height: 900px;
                max-width: 100%;
            }
        }
    }

    .c-apartment-details__3d-info {
        //border: 1px solid red;
        text-align: center;
        text-transform: uppercase;
        font-size: 1rem;
        margin: 2rem;
    }


        .c-apartment-details-nav {
            display: flex;
            justify-content: space-between;
            width: 80%;
            margin: 0 auto;
            @media (min-width: $screen-md-min) {
                width: 50%;
            }
        }

            .c-apartment-details-nav__left, .c-apartment-details-nav__right {
                font-size: $font-size-default;
                line-height: 1;
                height: 14px;
            }

            .c-apartment-details-nav__left {
                background: url(/imgs/arrow-to-left-grey_2x.png) left center / 31px 14px no-repeat;
                padding-left: 37px;
            }

            .c-apartment-details-nav__right {
                background: url(/imgs/arrow-to-right-grey_2x.png) right center / 31px 14px no-repeat;
                padding-right: 37px;
            }

    .c-apartment-details__rooms {
        display: block;
        @include size(100% auto);
        background: url('/imgs/aside_squares_2x.jpg') 10% bottom / 344px 139px no-repeat, #f3f3f4 url('/imgs/aside_squares_grey_2x.jpg') left top / 128px 80px no-repeat;
        @media (min-width: $screen-sm-min) {
            display: inline-block;
            @include size(400px 100%);
            vertical-align: top;
        }
    }
        .c-apartment-details__rooms-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // height: 100%;
            padding: 150px 50px 50px;
            @media (min-width: $screen-sm-min) {
                vertical-align: top;
                padding: 100px 50px 50px;
            }
            @media (min-width: $screen-md-min) {
                padding: 150px 50px 30px
            }
        }

            .c-apartment-details__rooms-num {
                width: 14rem;
                margin: 0 auto;
                padding-left: 55px;
                background: url('/imgs/north-red_2x.png') left center / contain no-repeat;
                h2 {
                    margin-bottom: 0;
                }
                span {
                    font-family: $font-family-main-book;
                    color: $brand-color-7;
                }
            }


            .c-apartment-details__rooms-table {
                // border: 1px solid red;
                width: 100%;
                margin-top: 50px;
                tr {
                    td {
                        // border: 1px solid red;
                        padding: 10px 0;
                        font-family: $font-family-main-book;
                        font-size: $font-size-default;
                        color: $brand-color-6;
                        &:first-child {
                            text-align: center;
                        }
                        &:last-child {
                            text-align: right;
                        }
                    }
                    th {
                        font-family: $font-family-main-bold;
                        font-size: $font-size-default;
                        text-transform: uppercase;
                        text-align: left;
                        padding-top: 3px;
                        &:first-child {
                            min-width: 17px;
                            background: url('/imgs/ukupno_square_2x.png') center / 19px 20px no-repeat;
                        }
                        &:last-child {
                            text-align: right;
                        }
                    }
                }
            }


            .c-apartment-details__rooms-img {
                @include size(380px auto);
                margin: 20px auto 0;
                // transform: rotateZ(90deg);
            }
