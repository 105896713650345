h1, h2, h3, h4, h5, h6 {
    line-height: 1.25;
    font-family: $font-family-main-medium;
    color: $brand-color-6;
    font-weight: normal;
}

h1 {
    margin-bottom: 3rem;
    font-size: $font-size-xl;
}

    .h1--xxl {
        font-size: 3rem;
        @media (min-width: $screen-md-min) {
            margin-bottom: 3rem;
            font-size: $font-size-xxxl;
        }
    }

h2 {
    font-size: 1.875rem;
    margin-bottom: 1rem;
}

h3 {
    text-transform: uppercase;
    font-size: $font-size-l;
    color: $brand-color-3;
    margin-bottom: 1.5rem;
}

    .h-2-blocks {
        padding-left: 2.5rem;
        background: url('/imgs/h-2-blocks_2x.png') left center / contain no-repeat;
        line-height: 1;
        @media (min-width: $screen-sm-min) {
            background-position: left top;
            line-height: 1;
        }
    }

        .h-2-blocks--bold-gold {
            font-weight: bold;;
            b {
                font-size: 2em;
                padding-left: 0.1em;
                padding-right: 0.15em;
                // color: #e3b52e;
                background-color: rgba(204,129,0,.9);
                text-shadow: 0px 1px 5px #f9c800;
                color: transparent;
                -webkit-background-clip: text;
            }
        }

    .h-3-blocks {
        padding-left: 2.5rem;
        min-height: 67px;
        background: url('/imgs/h-3-blocks_2x.png') left center / contain no-repeat
    }
