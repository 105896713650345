* {
   box-sizing: border-box;
}

a {
  font-family: $font-family-main-medium;
  color: $black;
  text-decoration: none;
  cursor: pointer; //todo: zasto ne radi??
  transition: all 0.3s ease-in-out;
  &:hover {
      cursor: pointer; //fix: sada radi
      color: $brand-color-2;
  }
  &[href^=tel] {
      color: inherit;
      text-decoration: none;
      font-family: inherit;
  }
}

button {
    cursor: pointer;
}

section {
    // padding: 5.625rem 0;
}

p {
    font-family: $font-family-main-book;
    font-size: $font-size-default;
    line-height: 1.5;
    color: $text-black;
    text-align: justify;
    // padding-bottom: 15px;
}

p + p {
    margin-top: 3rem;
}

// a + p {
//     margin-top: 3rem;
//     margin-bottom: 3rem;
// }
//
// p + a {
//     margin-top: 3rem;
//     margin-bottom: 3rem;
// }

p + .row {
    margin-top: 3.125rem;
}

b {
    color: $brand-color-2;
}

html, body {
    font-family: $font-family-main-medium;
    font-weight: normal;
    font-size: .7em;

    overflow-x: hidden;

    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering:    optimizeLegibility;
    -ms-text-rendering:     optimizeLegibility;
    -o-text-rendering:      optimizeLegibility;
    text-rendering:         optimizeLegibility;
    font-smoothing:         antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing:    antialiased;
    -ms-font-smoothing:     antialiased;
    -o-font-smoothing:      antialiased;
    font-smoothing:         antialiased;

    @media (min-width: $screen-xs-min) {
        font-size: .8em;
    }
    @media (min-width: $screen-lg-min) {
        font-size: 1em;
    }
    color: $text-black;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    // overflow: hidden;
}

body {
    // overflow-y: scroll;
    // -webkit-overflow-scrolling:touch;
    // background-color: $bg-color;
}

ul {
    list-style: none;
}

img {
    @include size(100% auto);
    margin-bottom: -5px;
}

input, textarea {
    -webkit-appearance: none;

    &:hover {
        cursor: text;
    }
    &[type='submit'] {
        &:hover {
            cursor: pointer;
        }
    }
}
