.c-thank-you {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 190px);
  h1, p {
    text-align: center;
  }
  h1 {
    margin-bottom: 1rem;
  }
}
