.c-apartment-carousel {
    &.slick-slider {
        margin-bottom: 0;
    }
    .slick-dots {
        left: 0;
        bottom: 20px;
    }
    .slick-arrow {
        @include size(50% 100%);
        // background: rgba(0,0,0,.3);
        background: none;
        margin: 0;
        z-index: 9999;
        &:hover {
            background: none;
        }
    }
    .slick-prev {
        left: 0;
        &:hover {
            cursor: url('/imgs/slick-prev.png'), auto;
        }
    }
    .slick-next {
        right: 0;
        &:hover {
            cursor: url('/imgs/slick-next.png'), auto;
        }
    }
}
