.navbar-toggle {
    position: absolute;
    top: 12px;
    right: 20px;
    // margin-right: $navbar-padding-horizontal;
    @include navbar-vertical-align(34px);
    background-color: transparent;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    z-index: 10000;
    // border-radius: $border-radius-base;

    // We remove the `outline` here, but later compensate by attaching `:hover`
    // styles to `:focus`.
    &:focus {
        outline: 0;
    }

    // Bars
    .icon-bar {
        display: block;
        width: 33px;
        height: 5px;
        background-color: #fff;
        box-shadow: 0 0 15px rgba(0,0,0,.2);
    }
    .icon-bar + .icon-bar {
        margin-top: 4px;
    }

    //@media (min-width: $grid-float-breakpoint) {
    //    display: none;
    //}
}


.mobile-header.container-fluid {
    .o-page-nav__nav-fb-icon {
        position: absolute;
        top: 17px;
        left: 20px;
    }
}
