.modal-toggler {
  display: none;
  &:checked {

  }
}

.modal-container {
  position: fixed;
  @include size(100%);
  top:  0;
  left: 0;
  background: rgba(black, 0.75);
  opacity: 0;
  color: white;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0s linear 0.5s;
  z-index: 10000;

  .modal-toggler:checked + & {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s;
  }
}

.modal {
  position: absolute;
  top:  50%;
  left: 50%;
  padding: 2rem;
  transform: translateX(-50%) translateY(-50%);
  &.close-modal-area {
    width:  100%;
    height: 100%;
  }

  &.modal-box {
    max-height: 90%;
    width: 95%;
    max-width: 1200px;
    overflow: scroll;
    background: #ebeef3;
    text-align: center;
    padding: 2em 2rem;
    // border-left: 15px solid $brand-color-1;
    box-shadow: 0 0 30px rgba(0,0,0,.5);
    h1 {
        text-align: left;
    }
    @media (min-width: $screen-md-min) {
      padding: 3rem 6rem;
      border-left: 15px solid #77B14E;
      &:before {
        display: block;
        content: '';
        @include size(15px 100%);
        background-color: #77B14E;
      }
    }
    h2 {
      padding: 0 50px;
    }
  }
}

  .modal__slider {
    //width: 800px;
  }

  .modal__info-call {
    font-size: 1.5rem;
    margin-top: 1rem;
    text-align: center;
    margin-bottom: 1rem;
  }

.toggle-modal-button {
  display: inline-block;
  @include size(32px);
  position: absolute;
  top: 20px;
  right: 20px;
  background: url('/imgs/btn-modal-close_2x.png') center / contain no-repeat;
  cursor: pointer;
  // transition: opacity .25s ease-in-out;
  &:hover, &:focus, &:active {
      opacity: .7;
  }
}
