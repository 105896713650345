.c-about-building {

}

    .c-about-building__images {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url('/imgs/bg-header-home_2x.jpg') center 60% / cover no-repeat;
        // background: linear-gradient(45deg, #0d4979, #5ec6f5);
        .col-md-6 {
            flex: 1;
            &:first-child {
                padding: 2rem;
            }
        }
        @media (min-width: 900px) {
            flex-direction: row;
            .col-md-6 {
                width: 50%;
                &:first-child {
                    padding: 0 40px;
                }
            }
        }
    }

        .c-about-building__carousel {
            @include size(100vw auto);
            max-width: 726px;
            box-shadow: 0 0 30px rgba(0,0,0,.5);
            border: 7px solid #fff;;
            .col-md-6 & {
                // display: flex;
                // justify-content: center;
                // align-items: stretch;
            }
            img {
                @include size(100% auto);
                max-width: 726px;
                max-height: 851px;
            }
        }


    .c-about-building__opis {
        background-color: #e1e4e9;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        p {
            margin-bottom: 30px;
        }
        .o-btn {
            margin-top: 20px;
            align-self: center;
            border: 1px solid #C5C5C5;
        }
        @media (min-width: $screen-sm-min) {
            padding: 3.125rem;
        }
    }

    .c-about-building__rok {
        text-align: center;
        padding: 2.5rem 0 2.2rem;
        background-color: #fff;
        span {
            font-family: $font-family-main-bold;
            font-size: $font-size-m;
            text-transform: uppercase;
        }
        .date {
            color: $brand-color-2;
            padding-left: 20px;
            border-left: 2px solid #e1e4e9;
        }
        @media (min-width: $screen-md-min) {
            span {
                font-size: $font-size-l;
            }
        }
    }

    .c-about-building__efikasnost {
        // height: 495px;
        background: url('/imgs/bg-efficient_2x.jpg') center / cover no-repeat;
        padding: 7rem 0;
        h1 {
            font-family: $font-family-secondary-extra-bold;
            color: $white;
            padding-bottom: 30px;
            margin-bottom: 30px;
            text-align: center;
            text-transform: uppercase;
            text-shadow: 0 0 8px rgba(16, 41, 0, .68);
            background: url('/imgs/h-3-blocks-horizontal_2x.png') center bottom / 71px 20px no-repeat;
        }
    }
