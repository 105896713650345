.footer {
    // border-top: 2px solid #C3CEDB;
    background: linear-gradient(to right, #e1e4e9 0%, #e1e4e9 50%, #C3CEDB 50%, #C3CEDB 100%);
    box-sizing: padding-box;
}

    .footer__copyrights {
        background-color: #aeb9c5;
        // margin-top: -2px;
        padding: 25px 0 21px 100px;

        .icon-copyright > p {
            font-size: 20px;
            line-height: 1.1;
            color: $white;
        }

        @media only screen and (max-width : 320px) {
            padding-left: 20px;
        }
    }

    .footer__contact {
        background-color: #C3CEDB;
        @include retina-image('/imgs/bg_email', 148px 112px, 'png');
        background-size: 148px 112px;
        background-repeat: no-repeat;
        background-position: -10px center;
        padding: 10px 0 10px 115px;
        font-size: 14px;
        color: #fff;
        @media (min-width: $screen-md-min) {
            text-align: right;
        }

        @media only screen and (max-width : 320px) {
            padding-left: 20px;
        }
    }
