.o-btn {
    display: inline-block;
    padding: 2rem 6rem 1.8rem;
    background-color: $white;
    color: $text-black;
    font-family: $font-family-main-bold;
    font-size: $font-size-base;
    text-transform: uppercase;
    // letter-spacing: 1px;
    transition: all 0.3s ease-in-out;
    border-radius: 0;
    cursor: pointer;
    &:hover {
        background-color: #a5d985;
        color: #fff;
    }
}

    .o-btn--xs {
        padding: 1rem 2.5rem .9rem;
        font-size: $font-size-s;
        font-family: $font-family-main-book;
    }

    .o-btn--s {
        padding: 1.5rem 2.5rem 1.3rem;
        font-size: $font-size-default;
        font-family: $font-family-main-book;
    }

    .o-btn--full-width {
        width: 100%;
        border: none;
        padding: 1.1rem 0;
    }

    .o-btn--grey {
        background-color: #F8F8F8;
        border: 1px solid #D1D1D1;
    }

    .o-btn--shadow {
        box-shadow: 0 2px 20px rgba(0,0,0,.5);
    }

    .o-btn--contact {
        background: $white url('/imgs/btn-icon-contact_2x.png') 30px center / 22px no-repeat;
        padding: 2rem 5rem 1.8rem 6.5rem;
        @media (min-width: $screen-sm-min) {
            background-size: 30px;
            background-position: 40px center;
        }
    }

    .o-btn--pill {
        border-radius: 300px;
    }

    .o-btn--arrow-left {
        background: #F8F8F8 url('/imgs/arrow-to-left-grey_2x.png') 20px center / 31px 14px no-repeat;
        padding-left: 62px;
    }

    .o-btn--arrow-right {
        background: #F8F8F8 url('/imgs/arrow-to-right-grey_2x.png') 95% center / 31px 14px no-repeat;
        padding-right: 62px;
    }
