.c-3d-animation {
    background: url('/imgs/bg-header-home_2x.jpg') center 60% / cover no-repeat;
    padding: 4rem 2rem 5rem;
    span {
        // display: block;
        // padding-bottom: 20px;
        font-family: $font-family-main-bold;
        font-size: $font-size-m;
        text-transform: uppercase;
        color: $white;
    }
    @media (min-width: $screen-md-min) {
        padding: 5rem 0 7rem;
    }
    .plyr {
        margin-top: 20px;
    }
}

    .c-3d-animation__video {
        // margin-top: 20px;
        box-shadow: 0 5px 30px rgba(0,9,9,1);
        outline: 5px solid $white;
    }
