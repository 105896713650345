.c-all-apartments-list {
    padding: 5rem 15px;
    @media (min-width: $screen-sm-min) {
        padding: 5rem 3.125rem;
    }
}

    .c-all-apartments-list__table {
        background-color: #f3f3f3;
        background: #f3f3f3 url('/imgs/logo-main-opacity_2x.png') center / contain no-repeat;
        // background: #fefdec url('/imgs/30-godina-opacity_2x.png') center / center no-repeat;
        border-left: 10px solid $brand-color-5;
        box-shadow: 0 10px 25px rgba(55, 55, 55, .18);
        .col-md-1 {
            @media (min-width: $screen-md-min) {
                width: 12.5%;
            }
        }
    }

        .c-all-apartments-list__row {
            li {
                padding: 30px 0;
                border: 1px solid #dadada;
                text-align: center;
                &.clickable {
                    cursor: pointer;
                    &:hover {
                        background-color: #dadada;
                    }
                }
                &.rezervisan {
                    background: url("/imgs/reserved-badge_2x.png") right top / 67px 58px no-repeat;
                }
                &.prodat {
                    background: url("/imgs/sold-badge_2x.png") right top / 67px 58px no-repeat;
                }
                &.current {
                    border: 1px solid #fe332f;
                    color: #fe332f;
                }
                span {
                    color: $brand-color-5;
                }
                a {

                }
                @media (min-width: $screen-md-min) {
                    // padding-left: 50px;
                }
            }
        }
