.slick-arrow {
    @include size(55px 30px);
    margin-top: -45px;
    &:before {
        font-size: 0;
    }
    transition: opacity .25s ease-in-out;
    @media (max-width: $screen-xs-max) {
        transform: scale(.6);
        transform-origin: top;
        margin-top: -54px;
    }
}

    .slick-prev {
        left: -50px;
        background: transparent url('/imgs/slick-prev_2x.png') center / contain no-repeat;
        &:hover {
            background: transparent url('/imgs/slick-prev_2x.png') center / contain no-repeat;
            opacity: .5;
        }
    }

    .slick-next {
        right: -50px;
        background: transparent url('/imgs/slick-next_2x.png') center / contain no-repeat;
        &:hover {
            background: transparent url('/imgs/slick-next_2x.png') center / contain no-repeat;
            opacity: .5;
        }
    }


.slick-dots {
    li {
        @include size(auto);
        button {
            background-color: #fff;
            @include size(25px 5px);
            padding: 0;
            box-shadow: 0 0 8px rgba(0,0,0,.5);
            &:before {
                display: none;
                font-size: 0;
            }
        }
        &.slick-active {
            button {
                background-color: $brand-color-2;
            }
        }
    }
}
