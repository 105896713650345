//
//  https://css-tricks.com/centering-css-complete-guide/
//

.t-vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.t-horizontal-align--flexbox {
    display: flex;
    justify-content: center;
}


.t-vertical-align--flexbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
