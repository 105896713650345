.icon-footer {
    padding-left: 25px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 17px;
    display: inline-block;
    height: 17px;
    margin: 5px 0 0 10px;
}

    .icon-header--email {
        @include retina-image('/imgs/icon_email', 17px, 'png');
    }

    .icon-header--phone {
        @include retina-image('/imgs/icon_phone', 17px, 'png');
    }

    .icon-header--mobile {
        @include retina-image('/imgs/icon_mobile', 17px, 'png');
    }

    .icon-header--address {
        @include retina-image('/imgs/icon_address', 17px, 'png');
    }



.icon-copyright {
    @include retina-image('/imgs/icon_copyright', 39px, 'png');
    background-repeat: no-repeat;
    background-size: 39px;
    padding-left: 50px;
    display: block;
}
