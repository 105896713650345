.c-choose-floor {
    // margin-top: 30px;
    @media (min-width: $screen-md-min) {
        margin-top: -15px;
    }
}


    .c-choose-floor__img {
        background: url('/imgs/choose-floor.jpg') center / contain no-repeat;
        transition: all .25s ease-in-out;
        svg {
            margin-top: 5px;
            &:last-child {
                display: none;
            }
        }
        .light-toggler:checked ~ & {
            background: url('/imgs/choose-floor-hover.jpg') center / contain no-repeat;
            svg {
                &:first-child {
                    display: none;
                }
                &:last-child {
                    display: inline-block;
                }
            }
        }
        &.animate-lights {
            animation: animate-lights 2s ease-in-out;
        }
    }

    .c-choose-floor__img-cd30 {
        background: url('/imgs/cd30/cd30-lights-off.jpg') center / contain no-repeat;
        transition: all .25s ease-in-out;
        svg {
            margin-top: 5px;
            &:last-child {
                display: none;
            }
        }
        .light-toggler:checked ~ & {
            background: url('/imgs/cd30/cd30-lights-on.jpg') center / contain no-repeat;
            svg {
                &:first-child {
                    display: none;
                }
                &:last-child {
                    display: inline-block;
                }
            }
        }
        &.animate-lights {
            animation: animate-lights-cd30 2s ease-in-out;
        }
    }

    .c-choose-floor__img-cd35 {
        background: url('/imgs/cd35/cd35-lights-off.jpg') center / contain no-repeat;
        transition: all .25s ease-in-out;
        svg {
            margin-top: 5px;
            &:last-child {
                display: none;
            }
        }
        .light-toggler:checked ~ & {
            background: url('/imgs/cd35/cd35-lights-on.jpg') center / contain no-repeat;
            svg {
                &:first-child {
                    display: none;
                }
                &:last-child {
                    display: inline-block;
                }
            }
        }
        &.animate-lights {
            animation: animate-lights-cd35 2s ease-in-out;
        }
    }

    .c-choose-floor__label {
        background-color: rgba(0,0,0,0.5);
        // height: 100px;
        margin-top: -5px;
        // margin-bottom: 130px;
        padding: 1rem;
        h2, p {
            text-align: center;
            color: #fff;
        }
        h2 {
            margin-bottom: 0rem;
            font-size: 3rem;
        }
        p {

        }
    }

    .c-choose-floor__img-lights {
        background: url('/imgs/choose-floor.jpg') center / contain no-repeat;
        @include size(1000px 839px);
    }

    .c-choose-floor__img-lights-cd30 {
        background: url('/imgs/cd30/cd30-lights-on.jpg') center / contain no-repeat;
        @include size(1280px 1080px);
    }

    .c-choose-floor__img-lights-cd35 {
        background: url('/imgs/cd35/cd35-lights-on.jpg') center / contain no-repeat;
        @include size(1280px 1080px);
    }

    .c-choose-floor__heading {
        background: linear-gradient(to right, #92ca69 0%,#a5d97f 100%);
        text-align: center;
        padding: 2.5rem;
        // box-shadow: 0 10px 50px rgba(0,0,0,1);
        margin-top: -5px;
        h1 {
            line-height: 1;
            margin: 0;
            color: white;
            font-size: 1.75rem;
            line-height: 1.4;
            text-transform: uppercase;
        }
    }

    .c-choose-floor__new-heading {
        background: #FC3636;
        text-align: center;
        padding: 2rem;
        // box-shadow: 0 10px 50px rgba(0,0,0,1);
        margin-top: 2rem;
        cursor: pointer;
        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }
        h2 {
            color: #fff;
            margin-bottom: 0;
            b {
                color: #fff;
            }
            // text-transform: uppercase;
        }
    }

    .c-choose-floor__turn-light {
        position: absolute;
        @include size(48px);
        top: 20px;
        right: 30px;
        cursor: pointer;
        background: url('/imgs/turn-the-light-on_2x.png') center / contain no-repeat;
        opacity: .4;
        .light-toggler:checked ~ & {
            opacity: 1;
        }
    }

@keyframes animate-lights {
    0% {

    }
    50% {
        background: url('/imgs/choose-floor-hover.jpg') center / contain no-repeat;
    }
    100% {
        background: url('/imgs/choose-floor.jpg') center / contain no-repeat;
    }
}

@keyframes animate-lights-cd30 {
    0% {

    }
    50% {
        background: url('/imgs/cd30/cd30-lights-on.jpg') center / contain no-repeat;
    }
    100% {
        background: url('/imgs/cd30/cd30-lights-off.jpg') center / contain no-repeat;
    }
}

@keyframes animate-lights-cd35 {
    0% {

    }
    50% {
        background: url('/imgs/cd35/cd35-lights-on.jpg') center / contain no-repeat;
    }
    100% {
        background: url('/imgs/cd35/cd35-lights-off.jpg') center / contain no-repeat;
    }
}
