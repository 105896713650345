.c-landing {
    @media (min-width: $screen-md-min) {
        width: 106vw;
    }
}

    .c-landing__item {
        height: 45vh;
        cursor: pointer;
        text-align: center;
        transition: all .25s ease-in-out;
        @media (min-width: $screen-md-min) {
            height: 100vh;
            min-height: 100vh;
            padding-top: 60px;
            &:hover {
                filter: brightness(120%);
                // transform: scale(1.01);
            }
        }
        h1 {
            color: #fff;
            font-family: $font-family-main-book;
            font-size: 2rem;
            text-transform: uppercase;
            // margin: 60px 0 0 -20px;
            margin-bottom: 1rem;
            b {
                font-size: 2em;
                padding-left: 0.1em;
                padding-right: 0.15em;
                // color: #e3b52e;
                background-color: rgba(204,129,0,.9);
                text-shadow: 0px 1px 3px #f9c800;
                color: transparent;
                -webkit-background-clip: text;
            }
            @media (min-width: $screen-md-min) {
                margin-left: -20px;
            }
        }
        p {
            text-align: center;
            font-size: 1.2rem;
            color: #fff;
        }
    }

        .c-landing__item--transport {
            background: url('/imgs/landing-transport.jpg') 10% center / cover no-repeat;
        }

        .c-landing__item--olge-petrov {
            background: url('/imgs/landing-invest2.jpg') 40% center / cover no-repeat;
            .c-landing__logo {
                background-color: rgba(105,173,104,.6);
            }
        }

        .c-landing__item--cara-dusana {
            background: url('/imgs/cd30/landing-page.jpg') 40% center / cover no-repeat;
            .c-landing__logo {
                background-color: rgba(105,173,104,.6);
            }
        }

        .c-landing__item--cara-dusana-35 {
            background: url('/imgs/cd35/landing-page-2.jpg') 40% center / cover no-repeat;
            .c-landing__logo {
                background-color: rgba(105,173,104,.6);
            }
        }


        .c-landing__item--left {
            @media (min-width: $screen-md-min) {
                clip-path: polygon(0 0, 100% 0, 93% 100%, 0% 100%);
                margin-right: -3vh;
            }
        }

        .c-landing__item--center {
            @media (min-width: $screen-md-min) {
                clip-path: polygon(7% 0, 100% 0, 93% 100%, 0% 100%);
                // margin-right: -3vh;
            }
        }

        .c-landing__item--right {
            @media (min-width: $screen-md-min) {
                clip-path: polygon(7% 0, 100% 0, 100% 100%, 0% 100%);
                margin-left: -3vh;
            }
        }


            .c-landing__logo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 100%;
                height: 100%;
                background-color: rgba(255,255,255,.6);
                padding: 5px;
                img {
                    height: 90px;
                    width: auto;
                }
                @media (min-width: $screen-md-min) {
                    position: absolute;
                    bottom: 20px;
                    // top: 70%;
                    height: 100px;
                    width: 100%;
                    img {
                        margin-left: -40px;
                    }
                }
                h1 {
                    // color: #000;
                    margin-top: 20px;
                    // margin-bottom: 20px;
                    text-shadow: 0 0 5px rgba(0,0,0,.8);
                }
            }
