.o-featured-icons {
    text-align: center;
    background-color: rgba(0,0,0,.5);
    padding: 1.5rem 0;
}

    .o-featured-icons__item {
        display: inline-block;
        text-align: center;
        width: 125px;
        // border: 1px solid red;
        + .o-featured-icons__item {
            margin-left: 15%;
        }
        img {
            display: block;
            margin: 0 auto 10px;
            @include size(auto 45px);
        }
        span {
            font-family: $font-family-main-book;
            font-size: $font-size-default;
            color: $white;
            text-transform: uppercase;
            text-align: center;
        }
        .o-featured-icons__icon-zvucna {
            height: 30px;
            margin: 0 auto 15px;
        }
        @media (min-width: 900px) and (max-width: $screen-md-max) {
            width: 100px;
            + .o-featured-icons__item {
                margin-left: 10%;
            }
        }
        @media (max-width: $screen-xs-max) {
            width: 80px;
            + .o-featured-icons__item {
                margin-left: 5%;
            }
        }
    }
