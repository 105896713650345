.o-page-nav {
    @include size(100% auto);
    // border: 1px solid red;
    padding: 40px 90px;
    display: inline-block;
    position: absolute;
}

    .o-page-nav__nav {
        display: inline-block;
        width: 100%;
        text-align: center;
        @media (min-width: $screen-md-min) {
            text-align: right;
        }
        li {
            display: inline-block;
            vertical-align: middle;
            a {
                font-size: $font-size-m;
                color: $white;
                text-shadow: 0 2px 8px rgba(0,0,0,.2);
                &:hover, &:active, &:focus, &.active {
                    color: $brand-color-3;
                }
            }
            + li {
                margin-left: 25px;
            }
        }
    }


        .o-page-nav__nav-fb-icon {
            display: inline-block;
            vertical-align: middle;
            @include size(32px);
            background: transparent url('/imgs/fb-icon_2x.png') center / 22px no-repeat;
            border: 1px solid #fff;
            border-radius: 6px;
        }
