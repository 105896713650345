.o-section-padding--s {
    padding: 3rem 2rem;
    @media (min-width: $screen-sm-min) {
        padding: 3rem 0;
    }
}

.o-section-padding--l {
    padding: 5rem 2rem;
    @media (min-width: $screen-sm-min) {
        padding: 5rem 0;
    }
}
