.c-apartment-opis {

}

    .c-apartment-opis__heading {
        background-color: #fe332f;
        text-align: center;
        padding: 2rem 1rem;
        h1, h2, h3 {
            margin: 0;
            color: #fff;
        }
    }

    .c-apartment-opis__content {
        padding: 7rem 0;
        text-align: center;
        p {
            text-align: center;
        }
        .o-btn {
            margin-top: 6rem;
        }
    }