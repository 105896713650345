.c-apartments-list-header {
    .o-btn {
        margin: 0 0 25px;
        @media (min-width: $screen-md-min) {
            margin: 0;
        }
    }
}

.c-apartments-list__select {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    select {
        font-size: $font-size-l;
        appearance: none;
        border: none;
        background: #f8f8f8 url('/imgs/dropdown-arrow@2x.png') 90% center / 16px no-repeat;
        border: 1px solid #d1d1d1;
        padding: 15px 50px;
        cursor: pointer;
    }
}

.c-apartments-list {
    display: flex;
    align-items: center;
}

.c-apartments2-list-container {
    padding: 0 15px;
    @media (min-width: $screen-sm-min) {
        padding: 0 3.125rem;
    }
}

    .c-apartments-list__left {

    }

        .c-apartments-list__type-select {
            li {
                + li {
                    margin-top: 2rem;
                }
                a {
                    font-family: $font-family-main-book;
                    font-size: $font-size-m;
                    color: $text-black;
                    &:hover, &.active {
                        color: $brand-color-1;
                    }
                }
            }
        }


    .c-apartments-list__right {

    }

        .c-apartments-list__type-example {
            @include size(auto 250px);
            float: right;
            margin-top: 40px;
            // border: 5px solid #fff;
            // box-shadow: 0 0 10px rgba(0,0,0,.3)
        }

        .c-apartments-list-item {
            a {
                margin-top: 50px;
                float: left;
                // color: $brand-color-2;
                // text-transform: uppercase;
                // text-decoration: underline;
                // &:hover {
                //     color: $brand-color-1;
                // }
            }
            @media (max-width: $screen-xs-max) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            @media (min-width: $screen-md-min) {
                h1 {
                    width: 80%;
                }
                a {
                    margin-top: 150px;
                }
            }
        }
