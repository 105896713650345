.c-home-header {
    background: url('/imgs/bg-header-home_2x.jpg') center / cover no-repeat;
    text-align: center;
    padding: 6rem 0 4rem;
    @media (min-width: $screen-sm-min) {
        padding: 8rem 0 4rem;
    }
    @media (min-width: $screen-md-min) {
        padding: 10rem 0 4rem;
    }
}

    .c-home-header__skyline {
        // text-align: left;
        svg {
            // @include size(100%);
        }
    }


        .c-home-header__skyline-svg {
            .light1, .light2, .light3, .light4, .light5, .light6, .light7, .light8 {
                animation: turn-the-light-on1 10s ease;
                fill: #fff;
            }
            .light1 {
                animation-name: turn-the-light-on1;
            }
            .light2 {
                animation-name: turn-the-light-on2;
            }
            .light3 {
                animation-name: turn-the-light-on3;
            }
            .light4 {
                animation-name: turn-the-light-on4;
            }
            .light5 {
                animation-name: turn-the-light-on5;
            }
            .light6 {
                animation-name: turn-the-light-on6;
            }
            .light7 {
                animation-name: turn-the-light-on7;
            }
            .light8 {
                animation-name: turn-the-light-on8;
            }
        }

    .c-home-header__logo {
        margin-top: 100px;
    }

    .c-home-header__new-locations {
        p {
            color: $white;
            text-align: center;
            margin-top: 2rem;
        }
    }

    .c-home-header__choose-help {
        background: url('/imgs/arrow-to-bottom_2x.png') center bottom / 18px 39px no-repeat;
        padding-bottom: 50px;
        span {
            color: $white;
            text-transform: uppercase;
            font-size: $font-size-l;
        }
        @media (min-width: $screen-md-min) {
            margin: -110px 0 0;
        }
    }

    .c-home-header__all-apartments {
        margin-top: 4rem;
    }


@keyframes turn-the-light-on1 {
  0%   { fill: #000; }
  10%  { fill: #000; }
  12%  { fill: #fff; }
  100% { fill: #fff; }
}

@keyframes turn-the-light-on2 {
  0%   { fill: #000; }
  20%  { fill: #000; }
  22%  { fill: #fff; }
  100% { fill: #fff; }
}

@keyframes turn-the-light-on3 {
  0%   { fill: #000; }
  30%  { fill: #000; }
  32%  { fill: #fff; }
  100% { fill: #fff; }
}

@keyframes turn-the-light-on4 {
  0%   { fill: #000; }
  40%  { fill: #000; }
  42%  { fill: #fff; }
  100% { fill: #fff; }
}

@keyframes turn-the-light-on5 {
  0%   { fill: #000; }
  50%  { fill: #000; }
  52%  { fill: #fff; }
  100% { fill: #fff; }
}

@keyframes turn-the-light-on6 {
  0%   { fill: #000; }
  60%  { fill: #000; }
  62%  { fill: #fff; }
  100% { fill: #fff; }
}

@keyframes turn-the-light-on7 {
  0%   { fill: #000; }
  70%  { fill: #000; }
  72%  { fill: #fff; }
  100% { fill: #fff; }
}

@keyframes turn-the-light-on8 {
  0%   { fill: #000; }
  80%  { fill: #000; }
  82%  { fill: #fff; }
  100% { fill: #fff; }
}
