.o-logo-main {
    @include size(460px 223px);
    background: url('/imgs/logo-main_2x.png') center / contain no-repeat;
}

.o-logo-mobile {
    @include size(230px 111px);
    background: url('/imgs/logo-main_2x.png') center / contain no-repeat;
    margin: 0 auto;
}

.o-logo-nav {
    @include size(146px 70px);
    background: url('/imgs/logo-main_2x.png') center / contain no-repeat;
    margin: 15px auto;
    cursor: pointer;
    .p-homepage  & {
        display: none;
    }
}
