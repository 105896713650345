//-----------------------------------------------------------------------------/
//  Settings
//
//  @Colors..................Global Colors
//  @Typography..............Typography Styles
//  @Spacing.................Horiontal & Vertical Spacing
//
//--------------------------------------------------*/



// @Colors
// ===========================================


// B & W
$white:                         #fff;
$black:                         #444;
$text-black:                    #51565b;


// Grays
$grey:                          #ccc;
$grey-light:                    lighten($grey, 10%);
$grey-lighter:                  lighten($grey, 20%);
$grey-dark:                     darken($grey, 10%);
$grey-darker:                   darken($grey, 20%);


// Brand Colors
$bg-color:                      #E7E8ED;
$brand-color-1:                 #4f8626;
$brand-color-2:                 #ff4b39;
$brand-color-3:                 #3FE0DE;
$brand-color-4:                 #FF8D63;
$brand-color-5:                 #63AFFF;
$brand-color-6:                 #464860;
$brand-color-7:                 #FD332F;

// Links
// $link-color:                    $brand-color-1;
// $link-hover-color:              darken($link-color, 15%);


// @Typography
// ===========================================

// Font Stacks
$font-gotham-thin:                   'Gotham Thin', Arial, sans-serif;
$font-gotham-x-light:                'Gotham XLight', Arial, sans-serif;
$font-gotham-light:                  'Gotham Light', Arial, sans-serif;
$font-gotham-book:                   'Gotham Book', Arial, sans-serif;
$font-gotham-medium:                 'Gotham Medium', Arial, sans-serif;
$font-gotham-bold:                   'Gotham Bold', Arial, sans-serif;
$font-gotham-black:                  'Gotham Black', Arial, sans-serif;
$font-gotham-ultra:                  'Gotham Ultra', Arial, sans-serif;


$font-butler-ultra-light:            'Butler UltraLight', Arial, sans-serif;
$font-butler-light:                  'Butler Light', Arial, sans-serif;
$font-butler-medium:                 'Butler Medium', Arial, sans-serif;
$font-butler-regular:                'Butler Regular', Arial, sans-serif;
$font-butler-bold:                   'Butler Bold', Arial, sans-serif;
$font-butler-extra-bold:             'Butler ExtraBold', Arial, sans-serif;
$font-butler-black:                  'Butler Black', Arial, sans-serif;

// Copy Font
$font-family-main-thin:              $font-gotham-thin;
$font-family-main-x-light:           $font-gotham-x-light;
$font-family-main-light:             $font-gotham-light;
$font-family-main-book:              $font-gotham-book;
$font-family-main-medium:            $font-gotham-medium;
$font-family-main-bold:              $font-gotham-bold;
$font-family-main-black:             $font-gotham-black;
$font-family-main-ultra:             $font-gotham-ultra;

$font-family-secondary-ultra-light:            $font-butler-ultra-light;
$font-family-secondary-light:                  $font-butler-light;
$font-family-secondary-medium:                 $font-butler-medium;
$font-family-secondary-regular:                $font-butler-regular;
$font-family-secondary-bold:                   $font-butler-bold;
$font-family-secondary-extra-bold:             $font-butler-extra-bold;
$font-family-secondary-black:                  $font-butler-black;


// Font Sizes
$font-size-xs:               .750rem; // 12px
$font-size-s:                .875rem; // 14px
$font-size-default:             1rem; // 16px
$font-size-base:            1.125rem; // 18px
$font-size-m:               1.250rem; // 20px
$font-size-l:               1.563rem; // 25px
$font-size-xl:              2.188rem; // 35px
$font-size-xxl:              3.75rem; // 60px
$font-size-xxxl:            4.625rem; // 74px



// @Spacing
// ===========================================


$parallax-perspective : 1 !default;
$parallax-element     : "body" !default;
$parallax-ios         : true !default;


$grid-gutter-width:         0;
