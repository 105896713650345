// @include font-face("Gotham Thin", "/fonts/gotham/Gotham-Thin"); // missing
// @include font-face("Gotham XLight", "/fonts/gotham/Gotham-XLight"); // missing
@include font-face("Gotham Light", "/fonts/gotham/Gotham-Light");
@include font-face("Gotham Book", "/fonts/gotham/Gotham-Book");
// @include font-face("Gotham Medium", "/fonts/gotham/Gotham-Medium"); // missing
@include font-face("Gotham Bold", "/fonts/gotham/Gotham-Bold");
@include font-face("Gotham Black", "/fonts/gotham/Gotham-Black");
// @include font-face("Gotham Ultra", "/fonts/gotham/Gotham-Ultra"); // missing


@include font-face("Butler UltraLight", "/fonts/butler/Butler-UltraLight");
@include font-face("Butler Light", "/fonts/butler/Butler-Light");
@include font-face("Butler Medium", "/fonts/butler/Butler-Medium");
@include font-face("Butler Regular", "/fonts/butler/Butler");
@include font-face("Butler Bold", "/fonts/butler/Butler-Bold");
@include font-face("Butler ExtraBold", "/fonts/butler/Butler-ExtraBold");
@include font-face("Butler Black", "/fonts/butler/Butler-Black");
